import {
  checkDelayedResult,
  defer,
  hide,
  hideModal,
  isVariableDefinedNotNull,
  openModal,
  show,
} from '@slideslive/fuse-kit/utils';
import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return ['content', 'spinner'];
  }

  initialize(props = {}) {
    this.element.__fuseModalComponent = this;

    this.props = {
      loading: false,
      contentShown: false,
      userAction: null,
      userActionData: undefined,
      ...props,
    };

    this.options = null;
  }

  connect() {
    if (this.showOnInit) {
      defer(() => this.show());
    }
  }

  handleTurboBeforeVisit() {
    hideModal(this.element, { instant: true });
  }

  handleTouchmove(event) {
    if (event.target === this.element) {
      event.preventDefault();
    }
  }

  beforeShow(event) {
    this.options = event.detail || {};

    this.runCallback('beforeShow', { fireModalEvent: false });

    if (this.hasUrl) {
      this.loadFromUrl();
    } else {
      this.showContent();
    }
  }

  onShow() {
    this.runCallback('onShow', { fireModalEvent: false });
  }

  afterShow() {
    this.runCallback('afterShow', { fireModalEvent: false });
  }

  beforeHide() {
    this.runCallback('beforeHide', { fireModalEvent: false });
  }

  onHide() {
    this.runCallback('hide', { fireModalEvent: false });

    if (this.contentShown) {
      this.runCallback(this.userAction || 'reject', { data: this.userActionData });

      this.userAction = null;
      this.userActionData = undefined;
    }
  }

  afterHide() {
    if (this.loading) {
      return;
    }

    this.runCallback('afterHide', { fireModalEvent: false });

    if (this.hasUrl) {
      this.hideLoader();
      this.clearContent();
    }

    this.options = {};
    this.contentShown = false;
  }

  accept(closeModal = true, { data = undefined } = {}) {
    this.userAction = 'accept';
    this.userActionData = data;

    if (closeModal) {
      this.hide();
    } else {
      this.runCallback('accept', { data });
    }
  }

  reject(closeModal = true, { data = undefined } = {}) {
    this.userAction = 'reject';
    this.userActionData = data;

    if (closeModal) {
      this.hide();
    } else {
      this.runCallback('reject', { data });
    }
  }

  rejectByOutsideClick(event) {
    if (this.persistent || this.preventCloseOnOutsideClick) {
      return;
    }

    if (event.target === this.contentTarget || event.target === this.spinnerTarget) {
      this.reject();
    }
  }

  rejectByBgClick(event) {
    if (this.persistent || this.preventCloseOnOutsideClick) {
      return;
    }

    if (this.contentTarget.contains(event.target) && event.target !== this.contentTarget) {
      return;
    }

    this.reject();
  }

  show(options) {
    openModal(this.element, options);
  }

  hide() {
    hideModal(this.element);
  }

  runCallback(name, { fireModalEvent = true, data = undefined } = {}) {
    if (!this.options) {
      return;
    }

    if (typeof this.options[name] === 'function') {
      this.options[name].call(this, this);
    }

    if (fireModalEvent) {
      this.dispatch(name, { detail: data });
    }

    if (this.hasSourceElement) {
      this.dispatch(name, { target: this.sourceElement, bubbles: false, detail: data });
    }
  }

  loadFromUrl() {
    this.loading = true;

    this.showLoader();
    this.hideContent();

    const url = this.url;
    const method = this.options.requestMethod || 'GET';
    const xhr = new XMLHttpRequest();

    xhr.open(method, url);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.setRequestHeader(
      'Accept',

      'text/javascript, application/javascript, application/ecmascript, application/x-ecmascript, */*; q=0.01',
    );

    if (this.options.requestContentType) {
      xhr.setRequestHeader('Content-Type', this.options.requestContentType);
    }

    if (this.options.requestCsrfToken) {
      xhr.setRequestHeader('X-CSRF-Token', this.options.requestCsrfToken);
    }

    xhr.withCredentials = true;

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          const contentType = xhr.getResponseHeader('Content-Type').split(';')[0];

          if (contentType === 'application/json') {
            const json = JSON.parse(xhr.responseText);
            this.loadFromDelayedResult(url, json);
          } else {
            this.setContent(xhr.responseText);
            this.showContent();
          }

          this.loading = false;
        } else {
          console.warn('Loading modal from', url, 'failed:', xhr.status, xhr);

          this.runCallback('loadError');
          this.hide();
        }
      }
    };

    xhr.send(this.options.requestBody || null);
  }

  loadFromDelayedResult(url, response) {
    const delayedResultId = response.delayed_result_id;

    if (!delayedResultId) {
      console.warn('Loading modal from', url, ' from delayed result failed: No delayed result ID.');

      this.runCallback('loadError');
      this.hide();

      return;
    }

    const delayedResultUrl = `${this.delayedResultUrl}?id=${delayedResultId}`;

    checkDelayedResult(
      delayedResultUrl,
      (result) => {
        if (result.success) {
          this.setContent(result.html);
          this.showContent();
        } else if (result.errors) {
          console.warn('Loading modal from', url, ' from delayed result failed:', result.errors);

          this.runCallback('loadError');
          this.hide();
        }
      },
      500,
    );
  }

  setContent(html) {
    this.contentTarget.innerHTML = html;
  }

  clearContent() {
    this.contentTarget.innerHTML = '';
  }

  showContent() {
    this.contentShown = true;

    this.hideLoader();

    show(this.contentTarget, { useHiddenAttr: true });
    defer(() => this.runCallback('load'));
  }

  hideContent() {
    if (this.persistent) {
      return;
    }

    hide(this.contentTarget, { useHiddenAttr: true });
  }

  showLoader() {
    show(this.spinnerTarget, { useHiddenAttr: true });
  }

  hideLoader() {
    hide(this.spinnerTarget, { useHiddenAttr: true });
  }

  get modalName() {
    return this.element.dataset.modalName;
  }

  get showOnInit() {
    return this.element.dataset.showOnInit === 'true';
  }

  get hasUrl() {
    return this.options && isVariableDefinedNotNull(this.options.url);
  }

  get url() {
    let url = this.options.url;

    if (this.options.urlParams) {
      url += `?${this.options.urlParams}`;
    }

    return url;
  }

  get hasSourceElement() {
    return isVariableDefinedNotNull(this.options.sourceElement);
  }

  get sourceElement() {
    return this.options.sourceElement;
  }

  get modalContentController() {
    return this.findControllerByName(this.element.dataset.contentController);
  }

  get persistent() {
    return this.modalContentController && this.modalContentController.persistent;
  }

  get preventCloseOnOutsideClick() {
    return this.modalContentController && this.modalContentController.preventCloseOnOutsideClick;
  }

  get delayedResultUrl() {
    return this.element.dataset.delayedResultUrl || 'https://slideslive.com/-/delayed_result';
  }

  get loading() {
    return this.props.loading;
  }

  set loading(value) {
    this.props.loading = value;
  }

  get contentShown() {
    return this.props.contentShown;
  }

  set contentShown(value) {
    this.props.contentShown = value;
  }

  get userAction() {
    return this.props.userAction;
  }

  set userAction(value) {
    this.props.userAction = value;
  }

  get userActionData() {
    return this.props.userActionData;
  }

  set userActionData(value) {
    this.props.userActionData = value;
  }
}
