import { remove } from '@slideslive/fuse-kit/utils';
import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static values = {
    action: {
      type: String,
      default: 'accept',
    },
    modalComponentIdentifier: {
      type: String,
      required: true,
    },
  };

  connect() {
    const modalContentController = this._modalContentController;

    if (this._isAcceptAction) {
      modalContentController.acceptModal(true);
    } else {
      modalContentController.rejectModal(true);
    }

    remove(this.element);
  }

  get _isAcceptAction() {
    return this.actionValue === 'accept';
  }

  get _modalContentController() {
    return this.findParentControllerByName(this.modalComponentIdentifierValue, {
      hideWarning: true,
    });
  }
}
