import { defineTargetValuesFromDataset } from '@slideslive/fuse-kit/utils';
import ApplicationController from 'modules/application_controller';
import stimulus, { FUSE_MODAL_TRIGGER_CONTROLLER } from 'plugins/stimulus';

export default class extends ApplicationController {
  static targets = ['item'];

  initialize() {
    this.modalCache = new WeakMap();
  }

  itemTargetConnected(target) {
    if (this.isTurboPreview || this.modalCache.has(target)) return;

    this.initTrigger(target);
  }

  itemTargetDisconnected(target) {
    this.destroyItem(target);
  }

  destroyAllItems() {
    for (const item of this.itemTargets) {
      this.destroyItem(item);
    }
  }

  destroyItem(item) {
    if (!this.modalCache.has(item)) return;

    this.modalCache.delete(item);
  }

  initTrigger(item) {
    const data = this.createModalData(item);
    this.modalCache.set(item, data);

    if ((!this.itemValues(item).url || this.itemValues(item).url === '#') && item.href) {
      this.itemValues(item).url = item.href;
    }
  }

  createModalData(item) {
    return {
      values: defineTargetValuesFromDataset(item, 'modal', {
        name: {
          type: String,
          default: 'modalV2',
        },
        withHistory: {
          type: Boolean,
          default: false,
        },
        url: {
          type: String,
          default: undefined,
        },
        urlParams: {
          type: String,
          default: undefined,
        },
        requestMethod: {
          type: String,
          default: 'GET',
        },
        requestContentType: {
          type: String,
          default: undefined,
        },
        requestCsrfToken: {
          type: String,
          default: undefined,
        },
        requestBody: {
          type: String,
          default: undefined,
        },
        autofocus: {
          type: Boolean,
          default: true,
        },
      }),
    };
  }

  openModal(event) {
    if (!event) return;

    event.preventDefault();

    const target = stimulus.closestTarget(event.target, { [FUSE_MODAL_TRIGGER_CONTROLLER]: 'item' });
    const { name, ...options } = this.itemValues(target);

    if (['a', 'button'].includes(target.tagName.toLowerCase()) && !name && !options.url) return;
    if (target.href && target.href !== '#' && (event.altKey || event.ctrlKey || event.metaKey)) return;

    this.dispatchOnWindow('open', {
      prefix: 'modal',
      detail: { name, trigger: target, options },
    });
  }

  closeModal(event = null) {
    if (!event) return;

    event.preventDefault();

    const target = stimulus.closestStimulusTarget(event.target, { [FUSE_MODAL_TRIGGER_CONTROLLER]: 'item' });

    this.dispatchOnWindow('close', {
      prefix: 'modal',
      detail: { name: this.itemValues(target).name, trigger: target },
    });
  }

  itemData(item) {
    return this.modalCache.get(item);
  }

  itemValues(item) {
    return this.itemData(item)?.values;
  }
}
