import { addListener, createElementFromHTML, removeListener } from '@slideslive/fuse-kit/utils';

export default class LinkEditComponent {
  constructor(config) {
    this.data = { config };
    this.elements = {
      dom: this.renderDom(),
      field: null,
      input: null,
      confirmButton: null,
      cancelButton: null,
    };
    this.callbacks = {};
    this.listeners = [];

    this.renderChildren();
    this.initializeListeners();
  }

  initializeListeners() {
    if (this.listeners.length > 0) return;

    this.listeners.push(
      {
        target: this.domElement,
        id: addListener(this.domElement, 'submit', this.onSubmit.bind(this)),
      },
      {
        target: this.domElement,
        id: addListener(this.domElement, 'keydown', this.onKeyDown.bind(this)),
      },
      {
        target: this.inputElement,
        id: addListener(this.inputElement, 'input', this.onInput.bind(this)),
      },
      {
        target: this.cancelButtonElement,
        id: addListener(this.cancelButtonElement, 'click', this.onCancel.bind(this)),
      },
    );
  }

  removeListeners() {
    for (const { target, id } of this.listeners) {
      removeListener(target, { id });
    }
  }

  renderDom() {
    return createElementFromHTML('<form class="tw-flex tw-gap-1"></form>');
  }

  renderChildren() {
    this.elements = {
      ...this.elements,
      field: this.renderField(),
      input: null,
      confirmButton: this.renderConfirmButton(),
      cancelButton: this.renderCancelButton(),
    };

    this.domElement.innerHTML = '';

    this.domElement.appendChild(this.fieldElement);
    this.domElement.appendChild(this.confirmButtonElement);
    this.domElement.appendChild(this.cancelButtonElement);
  }

  renderField({ fieldHtml } = this.config) {
    return createElementFromHTML(fieldHtml.replace(/\{HREF_INPUT}/g, this.getId('input')));
  }

  renderConfirmButton({ confirmButtonHtml } = this.config) {
    return createElementFromHTML(confirmButtonHtml);
  }

  renderCancelButton({ cancelButtonHtml } = this.config) {
    return createElementFromHTML(cancelButtonHtml);
  }

  onSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    this.runCallback('confirm', this.inputElement?.value ?? '');
  }

  onCancel() {
    this.runCallback('cancel');
  }

  onKeyDown(event) {
    event.stopPropagation();

    if (event.key === 'Escape') {
      event.preventDefault();
      this.runCallback('cancel');
    }
  }

  onInput({ target }) {
    this.href = target.value;
  }

  runCallback(name, ...attrs) {
    for (const callback of this.callbacks[name] || []) {
      callback(...attrs);
    }
  }

  on(name, callback) {
    if (!this.callbacks[name]) {
      this.callbacks[name] = [];
    }

    this.callbacks[name].push(callback);
  }

  remove() {
    this.removeListeners();
    this.domElement.remove();
  }

  getId(suffix) {
    return `${this.config.id}_${suffix}`;
  }

  get config() {
    return this.data.config;
  }

  set config(value) {
    const rerenderChildren = JSON.stringify(this.config) !== JSON.stringify(value);

    this.data.config = value;

    if (rerenderChildren) {
      this.removeListeners();
      this.renderChildren();
      this.initializeListeners();
    }
  }

  set href(value) {
    if (this.inputElement.value !== value) {
      this.inputElement.value = value;
    }

    if (this.confirmButtonElement.disabled !== (value.length === 0)) {
      this.confirmButtonElement.disabled = value.length === 0;
    }
  }

  get domElement() {
    return this.elements.dom;
  }

  get fieldElement() {
    return this.elements.field;
  }

  get inputElement() {
    if (!this.elements.input) {
      this.elements.input = this.elements.field.querySelector('input') || this.elements.field;
    }

    return this.elements.input;
  }

  get confirmButtonElement() {
    return this.elements.confirmButton;
  }

  get cancelButtonElement() {
    return this.elements.cancelButton;
  }
}
