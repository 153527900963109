import {
  addListener,
  canonical,
  defer,
  fire,
  remove,
  removeListener,
  toParamsQueryString,
} from '@slideslive/fuse-kit/utils';
import Choices from 'choices.js';
import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return ['linkContainer', 'link'];
  }

  initialize() {
    this.listeners = [];
    this.choicesInputElementListenerIds = [];

    this.props = {
      choices: null,
      loading: false,
      searchCache: {},
      fetchRequest: null,
      mutationObserver: null,
    };
  }

  connect() {
    if (this.isTurboPreview) {
      if (this.element.multiple) {
        this.element.hidden = true;
      }

      return;
    }

    if (this.listeners.length === 0) {
      this.initListeners();
    }

    this.createChoices();
    this.initArgumentsObserver();
  }

  disconnect() {
    if (this.isTurboPreview) {
      return;
    }

    if (this.fetchRequest) {
      this.fetchRequest.abortController.abort();
      this.fetchRequest = null;
    }

    for (const listener of this.listeners) {
      removeListener(listener.target, { id: listener.id });
    }

    this.listeners = [];
    this.loading = false;
    this.searchCache = {};
  }

  initListeners() {
    this.listeners.push({
      target: document,
      id: addListener(document, 'turbo:before-cache', this.destroyChoices.bind(this)),
    });
    // this.popstateListenerId = addListener(window, 'popstate', this.destroyChoices.bind(this));
  }

  initArgumentsObserver() {
    if (this.mutationObserver) {
      return;
    }

    this.mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach((record) => {
        if (record.type === 'attributes') {
          switch (record.attributeName) {
            case 'disabled': {
              if (!this.choices) {
                break;
              }

              if (record.target.disabled) {
                this.choices.disable();
              } else {
                this.choices.enable();
              }

              break;
            }
            default:
              break;
          }
        }
      });
    });

    this.mutationObserver.observe(this.element, {
      attributes: true,
    });
  }

  disconnectArgumentsObserver() {
    if (!this.mutationObserver) {
      return;
    }

    this.mutationObserver.disconnect();
  }

  destroyBeforeDisconnectListeners() {
    for (const { target, id } of this.listeners) {
      removeListener(target, { id });
    }
  }

  destroyChoices() {
    this.destroyBeforeDisconnectListeners();
    this.disconnectArgumentsObserver();

    if (!this.choices) {
      return;
    }

    this.removeEventHandlersOnChoicesInputElement();
    this.choices.destroy();

    if (this.element.multiple) {
      this.element.hidden = true;
    }
  }

  createChoices() {
    if (this.choices) {
      return;
    }

    if (this.removeFirstBlank) {
      this.removeFirstBlankOption();
    }

    if (this.element.multiple) {
      this.element.hidden = false;
    }

    this.choices = new Choices(this.element, this.options);
    this.addPlaceholderOption();
    this.addAdditionalOptionsForQuery();
  }

  removeFirstBlankOption() {
    const option = this.element.querySelector("option[value='']");

    if (!option) {
      return;
    }

    remove(option);
  }

  handleChoicesInit() {
    this.setEventHandlersOnChoicesInputElement();

    defer(() => {
      this.dispatch('initialized', {
        detail: {
          ...this.choices.getValue(),
        },
      });
    });
  }

  setEventHandlersOnChoicesInputElement() {
    if (!this.autocompleteUrl) {
      return;
    }

    const setValueOnCommaKeyId = addListener(this.choicesInput, 'keydown', this.setValueOnCommaKey.bind(this));
    this.choicesInputElementListenerIds.push(setValueOnCommaKeyId);
  }

  removeEventHandlersOnChoicesInputElement() {
    for (const listenerId of this.choicesInputElementListenerIds) {
      removeListener(this.choicesInput, { id: listenerId });
    }

    this.choicesInputElementListenerIds = [];
  }

  handleCopy(event) {
    const choicesInput = this.choicesInput;

    if (event.target !== choicesInput) {
      return false;
    }

    if (choicesInput.selectionStart !== choicesInput.selectionEnd) {
      return false;
    }

    const highlightedItems = [this.choices.getValue()].flat().filter((item) => item.highlighted);

    if (highlightedItems.length > 0) {
      const highlightedItemValues = highlightedItems.map((item) => item.value);

      event.clipboardData.setData('application/x-slideslive-tags', JSON.stringify(highlightedItemValues));
      event.clipboardData.setData('text/plain', highlightedItemValues.join(', '));

      event.preventDefault();
    }

    return true;
  }

  handleCut(event) {
    if (!this.handleCopy(event)) {
      return;
    }

    this.choices.removeActiveItems();
  }

  handlePaste(event) {
    const choicesInput = this.choicesInput;

    if (event.target !== choicesInput) {
      return;
    }

    if (choicesInput.selectionStart !== choicesInput.selectionEnd) {
      return;
    }

    const highlightedItemsJson = event.clipboardData.getData('application/x-slideslive-tags');

    if (highlightedItemsJson) {
      const highlightedItems = JSON.parse(highlightedItemsJson);
      const existingTags = this.choices.getValue(true) || [];

      for (const item of highlightedItems) {
        if (!existingTags.includes(item)) {
          this.choices.setValue([item]);
        }
      }

      event.preventDefault();
    } else {
      const text = event.clipboardData.getData('text/plain');

      if (text && text.includes(',')) {
        const items = text.split(',').map((item) => item.trim());
        const existingTags = this.choices.getValue(true) || [];

        for (const item of items) {
          if (!existingTags.includes(item)) {
            this.choices.setValue([item]);
          }
        }

        event.preventDefault();
      }
    }
  }

  handleOriginalSelectChange(event) {
    const nativeValue = event.target.value;
    const choicesValue = this.choices.getValue(true);
    if (nativeValue === choicesValue) return;
    if (choicesValue.includes && choicesValue.includes(nativeValue)) return;

    this.choices.setChoiceByValue(nativeValue);
  }

  searchEvent(event) {
    const query = event.detail.value;

    this.search(query);
  }

  search(query) {
    if (this.fetchRequest) {
      this.fetchRequest.abortController.abort();
      this.fetchRequest = null;
    }

    if (this.searchCache[query.toLowerCase()]) {
      this.searchFromCache(query);
    } else {
      this.searchWithFetch(query);
    }
  }

  searchFromCache(query) {
    this.loading = false;
    this.clearDropdown();
    this.setDropdownContent(query, this.searchCache[query.toLowerCase()]);
  }

  searchWithFetch(query) {
    if (!this.loading) {
      this.loading = true;
      this.placeholderOptionText = this.loadingText;
      this.clearDropdown();
    }

    let data;

    if (this.autocompleteQueryData) {
      data = JSON.parse(this.autocompleteQueryData);
    } else {
      data = {};
    }

    const params = toParamsQueryString({ ...data, term: query });
    const fetchAbortController = new AbortController();
    const url = this.autocompleteUrl.includes('?')
      ? `${this.autocompleteUrl}&${params}`
      : `${this.autocompleteUrl}?${params}`;

    this.fetchRequest = fetch(url, {
      credentials: 'include',
      signal: fetchAbortController.signal,
    })
      .then((res) => res.json())
      .then((resData) => {
        this.searchCache[query] = resData;
        this.setDropdownContent(query, resData);

        this.loading = false;
        this.placeholderOptionText = this.noResultsText;
      })
      .catch((err) => {
        if (err.code === 20) {
          return;
        }

        this.loading = false;
        this.placeholderOptionText = this.noResultsText;
      });

    this.fetchRequest.abortController = fetchAbortController;
  }

  clearDropdown() {
    this.choices.clearChoices();
  }

  addPlaceholderOption() {
    if (!this.element.hasAttribute('placeholder')) {
      return;
    }

    this.choices.setChoices(
      [
        {
          disabled: true,
          value: '',
          selected: !this.choices.getValue(),
          label: this.element.getAttribute('placeholder'),
        },
      ],
      'value',
      'label',
      false,
    );
  }

  addAdditionalOptionsForQuery(query, foundQueryInExistingValues) {
    const additionalOptions = this.additionalOptionsForQuery(query, foundQueryInExistingValues);

    if (additionalOptions) {
      this.choices.setChoices(additionalOptions, 'value', 'label', false);
    }
  }

  setDropdownContent(query, data) {
    this.loading = false;

    const existingValues = this.choices.getValue(true) || [];
    let foundQueryInExistingValues = false;
    const content = {};

    const groups = [];
    let groupId = 0;
    const groupField = this.groupField;

    for (let i = 0; i < data.length; ++i) {
      const entry = data[i];

      let optionLabel = this.labelField.split('.').reduce((nestedEntry, key) => {
        if (nestedEntry && key in nestedEntry) {
          return nestedEntry[key];
        }

        return null;
      }, entry);
      let optionValue = this.valueField.split('.').reduce((nestedEntry, key) => {
        if (nestedEntry && key in nestedEntry) {
          return nestedEntry[key];
        }

        return null;
      }, entry);

      if (!optionLabel || !optionValue) {
        console.warn('Missing label or value field in select entry.', this.labelField, this.valueField, entry);
        continue;
      }

      optionLabel = optionLabel.toString();
      optionValue = optionValue.toString();

      if (this.showSelectedValuesInDropdown || !existingValues.includes(optionValue)) {
        const option = { label: optionLabel, value: optionValue };
        const customProperties = this.optionCustomPropertiesFor(entry, optionLabel, optionValue);
        if (customProperties) {
          option.customProperties = customProperties;
        } else {
          option.customProperties = entry;
        }

        let group;
        if (groupField) {
          group = entry[groupField];
        } else {
          group = 'group';
        }

        if (!content[group]) {
          groups.push(group);
          content[group] = {
            label: group,
            id: groupId++,
            disabled: false,
            choices: [],
          };
        }

        content[group].choices.push(option);
      }

      if (optionValue.toLowerCase() === query.toLowerCase()) {
        foundQueryInExistingValues = true;
      }
    }

    if (!foundQueryInExistingValues) {
      foundQueryInExistingValues = existingValues.includes(query);
    }

    this.choices.setChoices([], 'value', 'label', true);

    this.addPlaceholderOption();
    this.addAdditionalOptionsForQuery(query, foundQueryInExistingValues);

    let sortedGroups;
    let sortedContent;
    if (this.shouldSortSearchResults) {
      sortedGroups = this.sortSearchGroups(query, groups);
      sortedContent = this.sortSearchResults(query, content);
    } else {
      sortedGroups = groups;
      sortedContent = content;
    }

    if (groupField) {
      const groupedItems = sortedGroups.map((group) => sortedContent[group]);
      this.choices.setChoices(groupedItems, 'value', 'label', false);
    } else if (sortedContent.group) {
      this.choices.setChoices(sortedContent.group.choices, 'value', 'label', false);
    }
  }

  setValueOnCommaKey(event) {
    if (event.keyCode !== 188) {
      return;
    }

    const tag = event.target.value;

    if (tag.trim() !== '') {
      event.target.value = '';
      this.choices.setValue(tag.trim());

      this.loading = false;
      this.placeholderOptionText = this.searchText;
      this.clearDropdown();

      if (this.fetchRequest) {
        this.fetchRequest.abortController.abort();
        this.fetchRequest = null;
      }

      fire(this.element, 'change');
    }

    event.preventDefault();
  }

  optionCustomPropertiesFor() {
    return null;
  }

  additionalOptionsForQuery() {
    return null;
  }

  sortSearchGroups(query, groups) {
    return groups;
  }

  sortSearchResults(query, results) {
    const canonicalQuery = canonical(query).toLowerCase();

    const sortGroup = (items) => {
      items.sort((a, b) => {
        const aLabel = canonical(a.label).toLowerCase();
        const bLabel = canonical(b.label).toLowerCase();

        const aIndex = aLabel.indexOf(canonicalQuery);
        const bIndex = bLabel.indexOf(canonicalQuery);

        if (aIndex === 0 && bIndex !== 0) return -1;
        if (aIndex !== 0 && bIndex === 0) return 1;

        return aLabel.localeCompare(bLabel);
      });
    };

    for (const group of Object.keys(results)) {
      sortGroup(results[group].choices);
    }

    return results;
  }

  set placeholderOptionText(text) {
    const optionElement = this.element.parentNode.parentNode.querySelector('.choices__item.has-no-results');
    if (optionElement) {
      optionElement.textContent = text;
    }
  }

  get options() {
    const options = {
      allowHTML: true,
      shouldSort: false,
      searchFloor: this.searchFloor,
      searchResultLimit: this.searchResultLimit,
      itemSelectText: this.itemSelectText,
      noChoicesText: this.searchText,
      duplicateItemsAllowed: this.duplicateItemsAllowed,
      removeItemButton: this.removeItemButton,
      position: this.position,
      noResultsText: () => {
        if (this.loading) {
          return this.loadingText;
        }

        if (this.choicesInput.value === '') {
          return this.searchText;
        }

        return this.noResultsText;
      },
      callbackOnInit: () => this.handleChoicesInit(),
      callbackOnCreateTemplates: () => this.createTemplates,
    };

    if (this.element.hasAttribute('placeholder')) {
      options.searchPlaceholderValue = this.searchText;
    }

    return options;
  }

  get showSelectedValuesInDropdown() {
    return true;
  }

  get createTemplates() {
    return {};
  }

  get searchFloor() {
    return 1;
  }

  get searchResultLimit() {
    return 1000;
  }

  get itemSelectText() {
    return '';
  }

  get removeFirstBlank() {
    return this.element.getAttribute('data-choices-remove-first-blank') === 'true';
  }

  get searchText() {
    return this.element.getAttribute('data-choices-search-text') || 'Type the search term.';
  }

  get loadingText() {
    return this.element.getAttribute('data-choices-loading-text') || 'Loading…';
  }

  get noResultsText() {
    return this.element.getAttribute('data-choices-no-results-text') || 'No results found.';
  }

  get duplicateItemsAllowed() {
    return true;
  }

  get removeItemButton() {
    return true;
  }

  get position() {
    return this.element.getAttribute('data-choices-position') || 'auto';
  }

  get autocompleteUrl() {
    return this.element.getAttribute('data-choices-url');
  }

  get autocompleteQueryData() {
    return this.element.getAttribute('data-choices-query-data');
  }

  get valueField() {
    return this.element.getAttribute('data-choices-value-field') || 'tag';
  }

  get labelField() {
    return this.element.getAttribute('data-choices-label-field') || 'tag';
  }

  get groupField() {
    return this.element.getAttribute('data-choices-group-field');
  }

  get shouldSortSearchResults() {
    return this.element.getAttribute('data-choices-sort-search') === 'true';
  }

  get choicesInput() {
    return (
      (this.choices && this.choices.input && this.choices.input.element) ||
      this.element
        .closest('.choices')
        .querySelector('input.choices__input[type="search"], input.choices__input[type="text"]')
    );
  }

  get choices() {
    return this.props.choices;
  }

  set choices(value) {
    this.props.choices = value;
  }

  get loading() {
    return this.props.loading;
  }

  set loading(value) {
    this.props.loading = value;
  }

  get searchCache() {
    return this.props.searchCache;
  }

  set searchCache(value) {
    this.props.searchCache = value;
  }

  get fetchRequest() {
    return this.props.fetchRequest;
  }

  set fetchRequest(value) {
    this.props.fetchRequest = value;
  }

  get mutationObserver() {
    return this.props.mutationObserver;
  }

  set mutationObserver(value) {
    this.props.mutationObserver = value;
  }
}
