import { addListener, createElementFromHTML, removeListener } from '@slideslive/fuse-kit/utils';

export default class CodeBlockComponent {
  constructor(config, attrs) {
    this.data = { config, attrs };
    this.elements = {
      dom: this.renderDom(),
      content: null,
      actions: null,
      languageSelect: null,
      copyButton: null,
      contentWrapper: null,
    };
    this.callbacks = {};
    this.listeners = [];

    this.renderChildren();
    this.initializeListeners();
  }

  initializeListeners() {
    if (this.listeners.length > 0) return;

    this.listeners.push({
      target: this.languageSelectElement,
      id: addListener(this.languageSelectElement, 'change', this.onLanguageChange.bind(this)),
    });
  }

  removeListeners() {
    for (const { target, id } of this.listeners) {
      removeListener(target, { id });
    }
  }

  renderDom() {
    return createElementFromHTML(
      '<div class="fuse-not-prose tw-border tw-border-white/38 tw-bg-dark tw-text-sm tw-my-4 tw-rounded-2xl tw-p-4"></div>',
    );
  }

  renderChildren() {
    this.elements = {
      ...this.elements,
      contentWrapper: this.contentWrapperElement || this.renderContentWrapper(),
      content: this.contentElement || this.renderContent(),
      actions: this.renderActions(),
      languageSelect: this.renderLanguageSelect(),
      copyButton: this.renderCopyButton(),
    };

    this.domElement.innerHTML = '';

    this.actionsElement.appendChild(this.languageSelectElement);

    if (this.copyButtonElement) {
      this.actionsElement.appendChild(this.copyButtonElement);
    }

    this.contentWrapperElement.appendChild(this.contentElement);
    this.domElement.appendChild(this.actionsElement);
    this.domElement.appendChild(this.contentWrapperElement);
  }

  renderContent() {
    return createElementFromHTML(`<code id='${this.getId('content')}'></code>`);
  }

  renderActions() {
    return createElementFromHTML('<div contenteditable="false" class="tw-flex tw-justify-between tw-mb-2"></div>');
  }

  renderLanguageSelect({ selectLanguageHtml, languages, defaultLanguage } = this.config) {
    const selectedLanguage =
      this.attrs.language && languages.includes(this.attrs.language) ? this.attrs.language : defaultLanguage;
    const optionsHtml = languages
      .map((option) => {
        const value = typeof option === 'string' ? option : option.value;
        const label = typeof option === 'string' ? option : option.label;

        return `<option value="${value}"${value === selectedLanguage ? ' selected' : ''}>${label}</option>`;
      })
      .join('');
    const fieldElement = createElementFromHTML(selectLanguageHtml.replace(/\{LANGUAGE_SELECT/g, this.getId('select')));

    (fieldElement.querySelector('select') || fieldElement).innerHTML = optionsHtml;

    return fieldElement;
  }

  renderCopyButton({ copyButtonHtml } = this.config) {
    if (!copyButtonHtml) return createElementFromHTML('<span hidden></span>');

    return createElementFromHTML(copyButtonHtml.replace(/\{CONTENT_ID}/g, this.getId('content')));
  }

  renderContentWrapper() {
    return createElementFromHTML('<pre spellcheck="false"></pre>');
  }

  onLanguageChange({ target }) {
    this.runCallback('languageChanged', target.value);
  }

  runCallback(name, ...attrs) {
    for (const callback of this.callbacks[name] || []) {
      callback(...attrs);
    }
  }

  on(name, callback) {
    if (!this.callbacks[name]) {
      this.callbacks[name] = [];
    }

    this.callbacks[name].push(callback);
  }

  remove() {
    this.removeListeners();
    this.domElement.remove();
  }

  getId(suffix) {
    return `${this.config.id}_${suffix}`;
  }

  set attrs(value) {
    if (this.attrs.language !== value.language) {
      (this.languageSelectElement.querySelector('select') || this.languageSelectElement).value = value.language;
    }

    this.data.attrs = value;
  }

  get attrs() {
    return this.data.attrs;
  }

  set config(value) {
    const rerenderChildren = JSON.stringify(this.config) !== JSON.stringify(value);

    this.data.config = value;

    if (rerenderChildren) {
      this.removeListeners();
      this.renderChildren();
      this.initializeListeners();
    }
  }

  get config() {
    return this.data.config;
  }

  get domElement() {
    return this.elements.dom;
  }

  get contentElement() {
    return this.elements.content;
  }

  get actionsElement() {
    return this.elements.actions;
  }

  get languageSelectElement() {
    return this.elements.languageSelect;
  }

  get copyButtonElement() {
    return this.elements.copyButton;
  }

  get contentWrapperElement() {
    return this.elements.contentWrapper;
  }
}
