import { hide, show } from '@slideslive/fuse-kit/utils';
import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return ['close'];
  }

  connect() {
    if (this.persistent) {
      this.hideCloseButton();
    }
  }

  preventClose() {
    this.persistent = true;
  }

  allowClose() {
    this.persistent = false;
  }

  hideCloseButton() {
    hide(this.closeTarget, { useHiddenAttr: true });
  }

  showCloseButton() {
    show(this.closeTarget, { useHiddenAttr: true });
  }

  reloadModal() {
    this.modalController.reload();
  }

  acceptModal(closeModal = true, { data = undefined } = {}) {
    this.modalController.accept(closeModal, { data });
  }

  rejectModal(closeModal = true, { data = undefined } = {}) {
    this.modalController.reject(closeModal, { data });
  }

  replaceContent(html) {
    this.modalController.setContent(html);
  }

  acceptAndCloseFromEvent() {
    this.acceptModal(true);
  }

  set persistent(value) {
    if (value) {
      this.hideCloseButton();
    } else {
      this.showCloseButton();
    }

    this.element.dataset.persistent = value;
  }

  get persistent() {
    return this.element.dataset.persistent === 'true';
  }

  set preventCloseOnOutsideClick(value) {
    this.element.dataset.preventCloseOnOutsideClick = value;
  }

  get preventCloseOnOutsideClick() {
    return this.element.dataset.preventCloseOnOutsideClick === 'true';
  }

  get modalController() {
    return this.findParentControllerByName(this.element.getAttribute('data-modal-controller'), { hideWarning: true });
  }
}
