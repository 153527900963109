import { Editor, defaultValueCtx, editorViewCtx, editorViewOptionsCtx, parserCtx, rootCtx } from '@milkdown/core';
import { clipboard as milkdownClipboard } from '@milkdown/plugin-clipboard';
import { history as milkdownHistory, redoCommand, undoCommand } from '@milkdown/plugin-history';
import { indent as milkdownIndent } from '@milkdown/plugin-indent';
import { listenerCtx, listener as milkdownListener } from '@milkdown/plugin-listener';
import { prism as milkdownPrism, prismConfig } from '@milkdown/plugin-prism';
import {
  blockquoteSchema,
  bulletListSchema,
  codeBlockSchema,
  headingSchema,
  insertHrCommand,
  listItemSchema,
  commonmark as milkdownCommonmark,
  orderedListSchema,
  toggleEmphasisCommand,
  toggleInlineCodeCommand,
  toggleStrongCommand,
  turnIntoTextCommand,
} from '@milkdown/preset-commonmark';
import { insertTableCommand, gfm as milkdownGfm, toggleStrikethroughCommand } from '@milkdown/preset-gfm';
import { callCommand } from '@milkdown/utils';
import { resolveTwClasses } from '@slideslive/fuse-kit/utils';
import ApplicationController from 'modules/application_controller';
import { refractor } from 'refractor/lib/common';

import { codeBlockComponent, codeBlockConfigCtx } from './code_block';
import { gapCursorPlugin } from './gap_cursor_plugin';
import { linkTooltipConfigCtx, linkTooltipPlugin, toggleLinkWithTooltipAction } from './link_tooltip_plugin';
import { listItemBlockComponent } from './list_item_block';
import menuButtonHighlight from './menu_button_highlight';
import menuButtonsAvailabilityBySelection from './menu_buttons_availability_by_selection';
import menuLinkButtonAvailability from './menu_link_button_availability';
import { slashMenu, slashMenuConfigCtx, slashMenuPlugin } from './slash_menu_plugin';
import tabConfigSetting from './tab_config_setting';
import { tableTooltipConfigCtx, tableTooltipPlugin } from './table_tooltip_plugin';
import { toggleNodeTypeCommand, toggleSchemaCommand } from './toggle_commands';

const POSSIBLE_LANGUAGES_HIGHLIGHT = [
  'text',
  'bash',
  'css',
  'html',
  'javascript',
  'typescript',
  'ruby',
  'python',
  'java',
  'kotlin',
  'php',
  'sql',
  'json',
  'markdown',
  'yaml',
  'regex',
  'lua',
];

export default class extends ApplicationController {
  static get targets() {
    return [
      'fullscreen',
      'editorContainer',
      'controls',
      'editor',
      'textarea',
      'strongButton',
      'emphasisButton',
      'strikethroughButton',
      'linkButton',
      'codeButton',
      'otherMenuContent',
      'undoButton',
      'redoButton',
      'slashMenuItem',
      'codeBlockSelectLanguageTemplate',
      'codeBlockCopyButtonTemplate',
      'linkTooltipLinkTemplate',
      'linkTooltipFieldTemplate',
      'linkTooltipConfirmButtonTemplate',
      'linkTooltipCancelButtonTemplate',
      'linkTooltipEditButtonTemplate',
      'linkTooltipRemoveButtonTemplate',
      'linkTooltipCopyButtonTemplate',
      'tableTooltipAddRowBeforeButtonTemplate',
      'tableTooltipAddRowAfterButtonTemplate',
      'tableTooltipAddColBeforeButtonTemplate',
      'tableTooltipAddColAfterButtonTemplate',
      'tableTooltipDeleteSelectedCellsButtonTemplate',
      'tableTooltipSetAlignLeftButtonTemplate',
      'tableTooltipSetAlignCenterButtonTemplate',
      'tableTooltipSetAlignRightButtonTemplate',
    ];
  }

  static get classes() {
    return ['editable', 'activeStyle'];
  }

  initialize() {
    this.editor = null;
    this.originalValue = '';
  }

  connect() {
    this.initializeEditor();
  }

  disconnect() {
    this.destroy();
  }

  destroy() {
    if (this.editor) {
      this.editor.destroy();
      this.editor = null;
    }

    const editorContainerPaddingYClasses = [].slice
      .call(this.editorContainerTarget.classList)
      .filter((c) => c.match(/(-p[yt]?-|^p[yt]-)/));
    this.textareaTarget.classList.remove('tw-mt-1', 'tw-hidden', '[.markdown>&]:tw-block');
    this.textareaTarget.className = resolveTwClasses(...editorContainerPaddingYClasses, this.textareaTarget.className);

    this.editorContainerTarget.insertAdjacentElement('beforebegin', this.textareaTarget);
  }

  textareaTargetConnected(target) {
    this.originalValue = target.value;
  }

  async initializeEditor() {
    if (this.editor) return;

    if (!this.editorContainerTarget.contains(this.textareaTarget)) {
      const textareaPaddingYClasses = [].slice
        .call(this.textareaTarget.classList)
        .filter((c) => c.match(/(-p[yt]?-|^p[yt]-)/));
      const textareaPaddingXClasses = [].slice
        .call(this.textareaTarget.classList)
        .filter((c) => c.match(/(-p[x]?-|^p[x]-)/));

      this.textareaTarget.classList.remove(...textareaPaddingYClasses);
      this.editorContainerTarget.className = resolveTwClasses(
        ...textareaPaddingYClasses,
        this.editorContainerTarget.className,
      );
      this.controlsTarget.className = resolveTwClasses(...textareaPaddingXClasses, this.controlsTarget.className);

      this.textareaTarget.insertAdjacentElement('afterend', this.editorContainerTarget);
      this.editorContainerTarget.insertAdjacentElement('beforeend', this.textareaTarget);
    }

    // if (this.controlsTarget.parentElement !== this.textareaTarget.parentElement) {
    //   this.controlsTarget.insertAdjacentElement('afterend', this.textareaTarget);
    // }

    this.editor = await Editor.make();
    this.createEditor();

    this.element.stimulusController = this;
  }

  createEditor() {
    this.editor
      .config(this.configEditor.bind(this))
      .config(this.configEditorListener.bind(this))
      .config(this.configEditorPlugins.bind(this))
      .config(this.configEditorCustomComponents.bind(this))
      .use(milkdownCommonmark)
      .use(milkdownListener)
      .use(milkdownClipboard)
      .use(milkdownHistory)
      .use(milkdownPrism)
      .use(milkdownIndent)
      .use(gapCursorPlugin)
      .use(slashMenuPlugin)
      .use([milkdownGfm, tableTooltipPlugin].flat())
      .use(linkTooltipPlugin)
      .use([toggleSchemaCommand, toggleNodeTypeCommand])
      .use([listItemBlockComponent, codeBlockComponent])
      .create()
      .then(this.editorViewDispatchOverride.bind(this));
  }

  configEditor(context) {
    const editableTargetClasses = resolveTwClasses(this.textareaTarget.className, ...this.editableClasses);
    const editorTargetStyle = `height: ${this.textareaTarget.offsetHeight}px;`;

    this.textareaTarget.className = resolveTwClasses(
      this.textareaTarget.className,
      'tw-mt-1 tw-hidden [.markdown>&]:tw-block',
    );
    this.editorTarget.className = resolveTwClasses(this.editorTarget.className, '[.markdown>&]:tw-hidden');
    this.editorTarget.style.cssText = editorTargetStyle;

    context.set(rootCtx, this.editorTarget);
    context.set(defaultValueCtx, this.textareaTarget.value);

    context.update(editorViewOptionsCtx, (prev) => ({
      ...prev,
      attributes: { class: editableTargetClasses },
      editable: () => !this.element.classList.contains('disabled') || this.textareaTarget.readOnly,
    }));
  }

  configEditorListener(context) {
    const listener = context.get(listenerCtx);

    listener.markdownUpdated((ctx, markdown, prevMarkdown) => {
      if (markdown !== prevMarkdown) {
        this.textareaTarget.value = markdown.replace(/\n+$/, '');
        this.fireInputEvent();
      }
    });
  }

  configEditorPlugins(context) {
    tabConfigSetting(context);

    context.update(prismConfig.key, (prev) => ({
      ...prev,
      configureRefractor: () => refractor,
    }));

    context.update(slashMenuConfigCtx.key, (prev) => ({
      ...prev,
      contentHtml: `<div>${this.slashMenuItemTargets.map((item) => item.outerHTML).join('')}</div>`,
    }));

    context.update(linkTooltipConfigCtx.key, (prev) => ({
      ...prev,
      linkHtml: this.linkTooltipLinkTemplateTarget.innerHTML,
      fieldHtml: this.linkTooltipFieldTemplateTarget.innerHTML,
      confirmButtonHtml: this.linkTooltipConfirmButtonTemplateTarget.innerHTML,
      cancelButtonHtml: this.linkTooltipCancelButtonTemplateTarget.innerHTML,
      editButtonHtml: this.linkTooltipEditButtonTemplateTarget.innerHTML,
      removeButtonHtml: this.linkTooltipRemoveButtonTemplateTarget.innerHTML,
      copyButtonHtml: this.linkTooltipCopyButtonTemplateTarget.innerHTML,
    }));

    context.update(tableTooltipConfigCtx.key, (prev) => ({
      ...prev,
      addRowBeforeHtml: this.tableTooltipAddRowBeforeButtonTemplateTarget.innerHTML,
      addRowAfterHtml: this.tableTooltipAddRowAfterButtonTemplateTarget.innerHTML,
      addColBeforeHtml: this.tableTooltipAddColBeforeButtonTemplateTarget.innerHTML,
      addColAfterHtml: this.tableTooltipAddColAfterButtonTemplateTarget.innerHTML,
      deleteSelectedCellsHtml: this.tableTooltipDeleteSelectedCellsButtonTemplateTarget.innerHTML,
      setAlignLeftHtml: this.tableTooltipSetAlignLeftButtonTemplateTarget.innerHTML,
      setAlignCenterHtml: this.tableTooltipSetAlignCenterButtonTemplateTarget.innerHTML,
      setAlignRightHtml: this.tableTooltipSetAlignRightButtonTemplateTarget.innerHTML,
    }));
  }

  configEditorCustomComponents(context) {
    const availableLanguages = refractor.listLanguages();

    context.update(codeBlockConfigCtx.key, (prev) => ({
      ...prev,
      languages: POSSIBLE_LANGUAGES_HIGHLIGHT.filter((lang) => availableLanguages.includes(lang)),
      selectLanguageHtml: this.codeBlockSelectLanguageTemplateTarget.innerHTML,
      copyButtonHtml: this.codeBlockCopyButtonTemplateTarget.innerHTML,
    }));
  }

  editorViewDispatchOverride(editor) {
    const editorView = editor.ctx.get(editorViewCtx);

    menuButtonsAvailabilityBySelection(this.menuButtonsDisabledWhenEmpty, editorView);
    menuLinkButtonAvailability(this.linkButtonTargets, editor.ctx, editorView);

    editorView.dispatch = (tr) => {
      Object.getPrototypeOf(editorView).dispatch.call(editorView, tr);

      tabConfigSetting(editor.ctx, editorView);
      menuButtonHighlight(this.menuButtons, this.activeStyleClass, editorView);
      menuButtonsAvailabilityBySelection(this.menuButtonsDisabledWhenEmpty, editorView);
      menuLinkButtonAvailability(this.linkButtonTargets, editor.ctx, editorView);
    };
  }

  removeSlash() {
    this.editor.action((ctx) => {
      const { opened: slashMenuOpened } = ctx.get(slashMenu.key);

      if (!slashMenuOpened) return;

      const editorView = ctx.get(editorViewCtx);

      editorView.dispatch(
        editorView.state.tr.delete(editorView.state.selection.from - 1, editorView.state.selection.from),
      );
    });
  }

  focusEditor() {
    if (this.isMarkdownEditor) {
      this.textareaTarget.focus();
    } else {
      this.editor.action((ctx) => {
        const editorView = ctx.get(editorViewCtx);

        editorView.focus();
      });
    }
  }

  callCommand(command, attrs = undefined) {
    this.editor.action(callCommand(command.key, attrs));
  }

  toggleSchema(schema, attrs) {
    this.callCommand(toggleSchemaCommand, { schema, attrs });
  }

  toggleNodeType(type, attrs) {
    this.callCommand(toggleNodeTypeCommand, { type, attrs });
  }

  undo() {
    this.callCommand(undoCommand);
  }

  redo() {
    this.callCommand(redoCommand);
  }

  toggleHeading({ currentTarget }) {
    this.toggleSchema(headingSchema, { level: Number(currentTarget.dataset.level) });
  }

  turnIntoText() {
    this.callCommand(turnIntoTextCommand);
  }

  insertHr() {
    this.callCommand(insertHrCommand);
  }

  toggleCodeBlock() {
    this.toggleSchema(codeBlockSchema, { language: 'text' });
  }

  toggleCode() {
    this.callCommand(toggleInlineCodeCommand);
  }

  toggleBlockquote() {
    this.toggleSchema(blockquoteSchema);
  }

  insertTable() {
    this.callCommand(insertTableCommand);
  }

  toggleStrikethrough() {
    this.callCommand(toggleStrikethroughCommand);
  }

  toggleBulletList() {
    this.toggleSchema(bulletListSchema);
  }

  toggleOrderedList() {
    this.toggleSchema(orderedListSchema);
  }

  toggleTaskList() {
    this.toggleSchema(listItemSchema, { listType: 'task', checked: false });
  }

  toggleEmphasis() {
    this.callCommand(toggleEmphasisCommand);
  }

  toggleStrong() {
    this.callCommand(toggleStrongCommand);
  }

  toggleLink() {
    this.editor.action(toggleLinkWithTooltipAction);
  }

  toggleFullscreen() {
    if (!document.fullscreen) {
      this.fullscreenTarget.requestFullscreen();

      return;
    }

    if (document.fullscreenElement === this.fullscreenTarget) {
      document.exitFullscreen();
    }
  }

  toggleMarkdownWysiwyg() {
    if (this.isMarkdownEditor) {
      if (document.fullscreenElement !== this.fullscreenTarget) {
        this.editorTarget.style.cssText = `height: ${this.textareaTarget.offsetHeight}px;`;
      }

      this.setWysiwygEditorValueFromTextarea();
    } else if (document.fullscreenElement !== this.fullscreenTarget) {
      this.textareaTarget.style.cssText = `height: ${this.editorTarget.offsetHeight}px;`;
    }

    this.editorContainerTarget.classList.toggle('markdown', !this.isMarkdownEditor);

    this.focusEditor();
  }

  setWysiwygEditorValueFromTextarea() {
    this.editor.action((ctx) => {
      const editorView = ctx.get(editorViewCtx);
      const parser = ctx.get(parserCtx);
      const newState = parser(this.textareaTarget.value);
      const transaction = editorView.state.tr.replaceWith(0, editorView.state.doc.content.size, newState);

      editorView.dispatch(transaction);
    });
  }

  toggleEditable() {
    this.editor.action((ctx) => {
      ctx.update(editorViewOptionsCtx, (prev) => ({
        ...prev,
        editable: () => !prev.editable(),
      }));
    });
  }

  enableEditable() {
    this.editor.action((ctx) => {
      ctx.update(editorViewOptionsCtx, (prev) => ({
        ...prev,
        editable: () => true,
      }));
    });
  }

  disableEditable() {
    this.editor.action((ctx) => {
      ctx.update(editorViewOptionsCtx, (prev) => ({
        ...prev,
        editable: () => false,
      }));
    });
  }

  fireInputEvent() {
    this.textareaTarget.dispatchEvent(new Event('input'));
  }

  fireChangeEvent() {
    if (this.originalValue === this.textareaTarget.value) return;

    this.textareaTarget.dispatchEvent(new Event('change'));
  }

  get isMarkdownEditor() {
    return this.editorContainerTarget.classList.contains('markdown');
  }

  get menuButtonsDisabledWhenEmpty() {
    return this.codeButtonTargets;
  }

  get menuButtons() {
    return [
      {
        key: 'strong',
        targets: this.strongButtonTargets,
      },
      {
        key: 'emphasis',
        targets: this.emphasisButtonTargets,
      },
      {
        key: 'strike_through',
        targets: this.strikethroughButtonTargets,
      },
    ];
  }
}
