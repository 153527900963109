import { eraseCookie, readCookie, toParamsQueryString, urlParam } from '@slideslive/fuse-kit/utils';
import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return ['filter', 'clientSideFilter'];
  }

  static get values() {
    return {
      addMissingOptionsOnInitialCookiesLoad: {
        type: Boolean,
        default: false,
      },
      renderAfterInitialCookiesLoad: {
        type: Boolean,
        default: false,
      },
      updateUrlOnChange: {
        type: Boolean,
        default: false,
      },
    };
  }

  initialize() {
    this.blockChangedEvent = false;
    this.updateTimeout = null;
    this.clientSideUpdateTimeout = null;
  }

  connect() {
    if (this.isTurboPreview) {
      this.disableFilters();

      return;
    }

    this.loadFromCookie(this.addMissingOptionsOnInitialCookiesLoadValue);

    setTimeout(() => {
      this.update(null, this.renderAfterInitialCookiesLoadValue);
      this.updateClientSide();
    }, 100);
  }

  disconnect() {
    this.blockChangedEvent = false;

    if (this.updateTimeout) {
      clearTimeout(this.updateTimeout);
      this.updateTimeout = null;
    }

    if (this.clientSideUpdateTimeout) {
      clearTimeout(this.clientSideUpdateTimeout);
      this.clientSideUpdateTimeout = null;
    }
  }

  loadFromCookieFromEvent() {
    this.loadFromCookie();
  }

  loadFromCookie(addMissingOptions = false) {
    const cookie = JSON.parse(window.localStorage.getItem(this.cookieName) || readCookie(this.cookieName));
    const textsCookie =
      JSON.parse(window.localStorage.getItem(this.textsCookieName) || readCookie(this.textsCookieName)) || {};

    window.localStorage.setItem(this.cookieName, JSON.stringify(cookie));
    window.localStorage.setItem(this.textsCookieName, JSON.stringify(textsCookie));

    eraseCookie(this.cookieName);
    eraseCookie(this.textsCookieName);

    const urlFilters = urlParam('filter');

    if (!cookie && !urlFilters) return;

    this.blockChangedEvent = true;

    for (const target of this.filterTargets) {
      const filterName = this.elementFilterName(target);
      let value = urlFilters?.[filterName.replace(/\[]$/, '')];

      if (!value && target.dataset.doNotSaveToCookie !== 'true') {
        value = cookie?.[filterName];
      }

      if (value === undefined || value === null || value === '') continue;

      if (target.dataset.enDate) {
        target.dataset.enDate = value;
      } else if (target.tagName.toLowerCase() === 'select') {
        let found = false;

        for (const option of target.options) {
          if (option.value.toString() === value.toString()) {
            found = true;
            break;
          }
        }

        if (!found && !addMissingOptions) continue;

        if (!found) {
          const addOption = (optionValue, optionText) => {
            const option = document.createElement('option');
            option.selected = true;
            option.value = optionValue;
            option.textContent = optionText;
            target.add(option);
          };

          if (target.options.length === 0 && value !== 'NONE') {
            addOption('NONE', 'All');
          }

          addOption(value, textsCookie?.[filterName] || value);
        }

        target.value = value;
        target.dispatchEvent(new Event('change'));
      } else {
        target.value = value;
        target.dispatchEvent(new Event('change'));
      }
    }

    this.blockChangedEvent = false;
  }

  saveToCookie(filterData, filterTexts) {
    window.localStorage.setItem(this.cookieName, JSON.stringify(filterData));

    if (filterTexts) {
      window.localStorage.setItem(this.textsCookieName, JSON.stringify(filterTexts));
    }
  }

  updateClientSide(event) {
    if (this.blockChangedEvent) return;

    if (this.clientSideUpdateTimeout) {
      clearTimeout(this.clientSideUpdateTimeout);
      this.clientSideUpdateTimeout = null;
    }

    if (!event || event.type !== 'keyup') {
      this.throttledClientSideUpdate();
    } else {
      this.clientSideUpdateTimeout = setTimeout(this.throttledClientSideUpdate.bind(this), 300);
    }
  }

  throttledClientSideUpdate() {
    const filterData = this.clientSideFilterData;

    setTimeout(this.triggerClientSideChanged.bind(this, filterData.load), 0);
  }

  triggerClientSideChanged(clientSideFilterData) {
    this.dispatch('clientSideChanged', {
      target: document,
      detail: { filter: clientSideFilterData },
    });
  }

  update(event, rerenderFilter = false) {
    if (this.blockChangedEvent) return;

    if (this.updateTimeout) {
      clearTimeout(this.updateTimeout);
      this.updateTimeout = null;
    }

    const ignoreValues = event?.target?.dataset?.ignoreValues?.split(' ') || [];

    if (ignoreValues.includes(event?.target?.value)) return;

    rerenderFilter = rerenderFilter || (event && event.target.dataset.rerenderFilter === 'true');

    if (!event || event.type !== 'keyup') {
      this.throttledUpdate(rerenderFilter, !event);
    } else {
      this.updateTimeout = setTimeout(this.throttledUpdate.bind(this, rerenderFilter), 300);
    }
  }

  throttledUpdate(rerenderFilter) {
    const filterData = this.filterData;
    const filterTexts = this.filterTexts;

    this.saveToCookie(filterData.save, filterTexts);

    if (this.updateUrlOnChangeValue) {
      const filterState = { data: filterData, texts: filterTexts };
      let newSearchParams = window.location.pathname;

      const filtersToParams = Object.keys(filterData.url).reduce((result, key) => {
        const normalizedKey = key.replace(/\[]$/, '');
        return { ...result, [normalizedKey]: filterData.url[key] };
      }, {});
      const filtersQueryString = toParamsQueryString({ filter: filtersToParams });

      if (filtersQueryString) {
        newSearchParams = `?${filtersQueryString}`;
      }

      // ToDo: once implemented, if replaceHistoryState use replaceState else pushState

      window.history.replaceState(
        {
          ...window.history.state,
          filter: filterState,
        },
        undefined,
        newSearchParams,
      );
    }

    if (rerenderFilter) {
      this.disableFilters();
    }

    setTimeout(this.triggerChanged.bind(this, filterData.load, rerenderFilter), 0);
  }

  triggerChanged(filterData, rerenderFilter = false) {
    this.dispatch('changed', {
      target: document,
      detail: { filter: filterData, rerenderFilter },
    });
  }

  disableFilters() {
    for (const target of this.filterTargets) {
      target.disabled = true;
    }
  }

  elementsToFilterData(elements) {
    const data = {
      load: {},
      save: {},
      url: {},
    };

    for (const target of elements) {
      const filterName = this.elementFilterName(target);
      let defaultValue = target.dataset.defaultValue;
      let value = target.value;
      let urlValue;

      if (target.dataset.enDate) {
        if (defaultValue !== target.dataset.enDate) {
          urlValue = target.dataset.enDate;
        }

        value = target.dataset.enDate;
      } else if (target.tagName.toLowerCase() === 'select' && target.multiple) {
        const selectedOptions = [...target.options]
          .filter((option) => option.selected)
          .map((option) => option.value || option.text);
        let changedFromDefaultValue = true;

        if (!defaultValue && selectedOptions.length === 0) {
          changedFromDefaultValue = false;
        } else if (defaultValue && selectedOptions.length > 0) {
          try {
            defaultValue = JSON.parse(defaultValue).sort();
          } catch {
            defaultValue = [defaultValue];
          }

          changedFromDefaultValue = selectedOptions.sort().every((v, index) => v === defaultValue[index]);
        }

        if (changedFromDefaultValue) {
          urlValue = selectedOptions;
        }

        value = selectedOptions;
      } else if (defaultValue !== target.value) {
        urlValue = target.value;
      }

      if (!value) continue;

      data.load[filterName] = value;
      data.url[filterName] = urlValue;

      if (target.dataset.doNotSaveToCookie !== 'true') {
        data.save[filterName] = value;
      }
    }

    return data;
  }

  elementFilterName(element) {
    return element.dataset.filterName || element.name;
  }

  get cookieName() {
    return this.identifier;
  }

  get textsCookieName() {
    return `${this.cookieName}--texts`;
  }

  get clientSideFilterData() {
    return this.elementsToFilterData(this.clientSideFilterTargets);
  }

  get filterData() {
    return this.elementsToFilterData(this.filterTargets);
  }

  get filterTexts() {
    const texts = {};

    for (const target of this.filterTargets) {
      if (target.dataset.doNotSaveToCookie === 'true') continue;

      const filterName = this.elementFilterName(target);

      if (target.dataset.enDate) {
        texts[filterName] = target.dataset.enDate;
      } else if (target.tagName.toLowerCase() === 'select') {
        if (target.options[target.selectedIndex]) {
          texts[filterName] = target.options[target.selectedIndex].text;
        }
      } else {
        texts[filterName] = target.value;
      }
    }

    return texts;
  }
}
